/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Default } from "layouts";
import Helmet from "react-helmet";
import { T22 } from "../components";

const Article = ({ pageContext }) => {
  console.log(pageContext);
  const styles = css`
    .t0 {
      padding: 24px;
      max-width: 900px;
      margin: auto;
      margin-top: 64px;

      @media screen and (max-width: 960px) {
        font-size: 48px;
      }

      @media screen and (max-width: 580px) {
        font-size: 32px;
      }
    }

    .content {
      max-width: 1537px;
      margin: auto;
      padding: 24px;
      max-width: 60ch;
      position: relative;

      @media screen and (max-width: 960px) {
        font-size: 0.8rem;
        max-width: 100%;
      }

      @media screen and (max-width: 580px) {
        font-size: 0.7rem;
      }

      img {
        display: block;
        width: 100%;
        aspect-ratio: 16 / 7;
        object-fit: cover;
        margin-bottom: 1.7rem;
      }

      code {
        font-family: "IBM Plex Mono", monospace;
        font-size: 0.8rem;
      }

      p > code {
        padding: 4px 8px 1px;
        display: inline-block;

        @media screen and (max-width: 960px) {
          font-size: 0.7rem;
        }

        @media screen and (max-width: 580px) {
          font-size: 0.5rem;
        }
      }

      pre[class*="language-"] {
        border-radius: 8px;
        margin-bottom: 1.2rem;
        padding: 0.5em 0.8em;

        @media screen and (max-width: 960px) {
          border-radius: 4px;
          margin-bottom: 1rem;
          padding: 0.2em;
        }

        @media screen and (max-width: 580px) {
          font-size: 0.5rem;
        }
      }

      ul {
        list-style: inherit;
      }

      ol {
        list-style: decimal;
      }

      ul,
      ol {
        line-height: 2;
        margin: 40px 0;

        @media screen and (max-width: 960px) {
          margin: 24px 0;
          line-height: 1.2;
        }

        li {
          margin-left: 48px;
          padding-left: 8px;

          @media screen and (max-width: 960px) {
            margin-left: 24px;
            padding-left: 4px;
            margin-bottom: 8px;
          }
        }
      }

      a {
        color: inherit;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0.6em 0;
        line-height: 1.4;
      }

      h2 {
        @media screen and (max-width: 960px) {
          font-size: 32px;
        }

        @media screen and (max-width: 580px) {
          font-size: 24px;
        }
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      blockquote {
        background: rgba(255, 255, 255, 0.1);
        padding: 24px;
        margin-bottom: 1rem;
        border-left: 8px solid white;
      }
    }

    .foot {
      max-width: 1537px;
      margin: auto;
      margin-top: 40px;
      padding: 12px 12px 12px 24px;
      max-width: 60ch;
      background: #1da1f2;
      color: white;
      display: flex;
      align-items: center;
      gap: 24px;
      border-radius: 3px;
      font-size: 20px;

      a {
        color: inherit;
        font-weight: 900;
      }

      svg {
        width: 40px;
        path {
          fill: white;
        }
      }

      a:last-of-type {
        margin-left: auto;
        background: white;
        color: #121212;
        padding: 16px 32px;
        display: block;
        border-radius: 32px;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        word-break: keep-all;
      }

      @media screen and (max-width: 860px) {
        margin: 40px 24px 0;
        font-size: 16px;
      }

      @media screen and (max-width: 690px) {
        flex-direction: column;
        font-size: 16px;
        padding: 24px;
        text-align: center;

        a:last-of-type {
          margin-left: unset;
        }
      }
    }
  `;

  const TwitterLogo = () => (
    <svg version="1.1" id="Logo" x="0px" y="0px" viewBox="0 0 248 204">
      <path d="M221.95,51.29c0.15,2.17,0.15,4.34,0.15,6.53c0,66.73-50.8,143.69-143.69,143.69v-0.04   C50.97,201.51,24.1,193.65,1,178.83c3.99,0.48,8,0.72,12.02,0.73c22.74,0.02,44.83-7.61,62.72-21.66   c-21.61-0.41-40.56-14.5-47.18-35.07c7.57,1.46,15.37,1.16,22.8-0.87C27.8,117.2,10.85,96.5,10.85,72.46c0-0.22,0-0.43,0-0.64   c7.02,3.91,14.88,6.08,22.92,6.32C11.58,63.31,4.74,33.79,18.14,10.71c25.64,31.55,63.47,50.73,104.08,52.76   c-4.07-17.54,1.49-35.92,14.61-48.25c20.34-19.12,52.33-18.14,71.45,2.19c11.31-2.23,22.15-6.38,32.07-12.26   c-3.77,11.69-11.66,21.62-22.2,27.93c10.01-1.18,19.79-3.86,29-7.95C240.37,35.29,231.83,44.14,221.95,51.29z" />
    </svg>
  );

  const twitterIntent = {
    text: pageContext.title,
    url: `https://jv-la.com/${pageContext.slug}`,
    via: "studiojvla",
  };

  const INTENT_URL = `https://twitter.com/intent/tweet?text=${twitterIntent.text}&url=${twitterIntent.url}&via=${twitterIntent.via}`;

  return (
    <Default>
      <Helmet>
        <title>{pageContext.title} | Jack-Edward Oliver</title>
      </Helmet>
      {pageContext.status === "unlisted" && (
        <T22.ArticleAlert>
          This article is currently marked as 'unlisted'. Please think twice
          before sharing. ;-)
        </T22.ArticleAlert>
      )}
      <div css={styles}>
        <div className="t0">{pageContext.title}</div>

        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: pageContext.content }}
        ></div>
        {pageContext.status !== "unlisted" && (
          <div className="foot">
            <TwitterLogo />
            <span>If you liked this article, please consider sharing it.</span>
            <a target="_blank" href={INTENT_URL}>
              Tweet {`->`}
            </a>
          </div>
        )}
        <T22.NewsletterBlock />
      </div>
      <hr />
    </Default>
  );
};

export default Article;
